import { Rating } from '@mui/material';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import OutlineStar from '@/assets/icons/rating-star--outline.svg';
import Star from '@/assets/icons/rating-star.svg';
import { ANONYMOUS_AVATAR, DEFAULT_AVATAR } from '@/constants/user';
import { boxBorder } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Container = styled.figure`
	background-color: ${props => props.color};
	display: flex;
	flex-direction: column;
	${boxBorder()}
	font-size: 14px;
	height: 338px;
	margin-block: 0;
	margin-inline: 0;
	max-width: 278px;
	min-width: 278px;
	padding: 24px;
	position: relative;

	&:before {
		content: '”';
		display: none;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 108px;
		font-weight: 800;
		height: 0;
		left: 182px;
		position: relative;
		top: -84px;
		width: fit-content;
		@media ${devices.laptop} {
			display: block;
		}
	}

	&::after {
		background-image: url('image/shadow.svg');
		content: '';
		display: block;
		height: 328px;
		left: -8px;
		position: absolute;
		top: 18px;
		width: 278px;
		z-index: -1;
	}
`;

const RatingItem = styled.div`
	align-items: center;
	display: flex;
	span {
		font-size: 14px;
	}
	.MuiRating-root {
		gap: 8px;
	}
`;

const Description = styled.blockquote`
	height: 188px;
	margin-block: 12px, 0;
	margin-inline: 0;
`;

const Author = styled.figcaption`
	display: flex;
	flex-direction: column;
`;
const User = styled.cite`
	align-items: center;
	display: flex;
`;
const Avatar = styled(Image)`
	height: 22px;
	object-fit: cover;
	width: 22px;
	${boxBorder('1px', '50%')}
`;
const Name = styled.div`
	font-style: normal;
	line-height: 18px;
	margin-left: 8px;
`;
const Program = styled.div`
	margin-top: 10px;
`;

const ExpandClick = styled.span`
	border-bottom: solid 1px ${color.primaryColor};
	color: ${color.primaryColor};
	cursor: pointer;
`;

interface FeedbackCardProps {
	rating: number;
	content: string;
	avatar: string;
	userName: string;
	isAnonymous: boolean;
	programName: string;
	cardColor: string;
}

const maxContentLength = 142;
const anonymousAvatar = ANONYMOUS_AVATAR;
const defaultAvatar = DEFAULT_AVATAR;

const FeedbackCard = ({
	rating,
	content,
	avatar,
	userName,
	isAnonymous,
	programName,
	cardColor
}: FeedbackCardProps) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [showExpand, setShowExpand] = useState(false);

	useEffect(() => {
		if (content.length > maxContentLength) {
			setShowExpand(true);
		}
	}, []);

	const displayText =
		isExpanded || content.length < maxContentLength
			? `${content} `
			: `${content?.slice(0, maxContentLength)} ... `;
	const buttonText = isExpanded ? '收起' : '更多';

	const displayName = isAnonymous ? '匿名' : userName;
	const displayAvatar = isAnonymous ? anonymousAvatar : avatar || defaultAvatar;

	return (
		<Container color={cardColor}>
			<RatingItem>
				<Rating
					name="read-only"
					value={rating}
					readOnly
					icon={<Star />}
					emptyIcon={<OutlineStar />}
					size="small"
				/>
			</RatingItem>
			<Description>
				{displayText}
				{showExpand && (
					<ExpandClick onClick={() => setIsExpanded(!isExpanded)}>
						{buttonText}
					</ExpandClick>
				)}
			</Description>
			<Author>
				<User>
					<Avatar
						src={displayAvatar}
						alt={displayName}
						unoptimized
						loader={imageLoader}
						width={50}
						height={50}
					/>
					<Name>{displayName}</Name>
				</User>
				<Program>{programName}</Program>
			</Author>
		</Container>
	);
};

export default FeedbackCard;
