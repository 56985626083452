import Image from 'next/image';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Modal from '.';
import imageLoader from '@/utils/loader';
import { getVideoUrl } from '@/utils/url';

const maxVideoWidth = '864px';
const ModalContent = styled.div`
	cursor: pointer;
	height: calc(80vw * 9 / 16);
	max-height: calc(${maxVideoWidth} * 9 / 16);
	max-width: ${maxVideoWidth};
	width: 80vw;
`;

const Cover = styled.div`
	height: 100%;
	width: 100%;
	&:before {
		background: url('/icon/video-player-icon.svg') no-repeat center;
		content: '';
		height: 44px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 44px;
	}
	img {
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
`;

export interface PreviewVideoFile {
	id: string;
	title: string;
	thumbnail?: string;
	youtubeId?: string;
	bilibiliId?: string;
	url?: string;
}

interface VideoPlayerModalProps {
	video: PreviewVideoFile | undefined;
	onClose: () => void;
}

/**
 * 优先 video.url
 * 没有根据 video.youtubeId 和 video.bilibiliId 获取videoSrc
 */
const VideoPlayerModal = ({ video, onClose }: VideoPlayerModalProps) => {
	const [isVisible, setIsVisible] = useState<boolean>(false);

	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	const videoSrc = video ? video.url || getVideoUrl(video?.youtubeId, video?.bilibiliId) : '';

	useEffect(() => {
		if (video && video.id) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	}, [video]);

	return (
		<Modal isVisible={isVisible} handleClose={onClose}>
			<ModalContent>
				{!video?.thumbnail || (isPlaying && videoSrc) ? (
					<iframe
						title={video?.title}
						id={video?.id}
						src={videoSrc}
						scrolling="no"
						allowFullScreen
						referrerPolicy="no-referrer"
						width="100%"
						height="100%"
						allow="autoplay"
						style={{
							position: 'relative',
							borderColor: 'transparent'
						}}
					/>
				) : (
					<Cover onClick={() => setIsPlaying(true)}>
						<Image
							src={video?.thumbnail}
							alt="thumbnail"
							unoptimized
							loader={imageLoader}
							width={0}
							height={0}
						/>
					</Cover>
				)}
			</ModalContent>
		</Modal>
	);
};

export default VideoPlayerModal;
