'use client';

import { Grid } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';

import HomeSection from './components/HomeSection';
import ModalContent from '@/components/Pages/CareerCommunityPage/ModalContent';
import PrimeCommunity from '@/components/Pages/CareerCommunityPage/PrimeCommunity';
import Modal from '@/components/Shares/Modal';

const HomeCommunitiesContainer = styled.div`
	position: relative;
`;

const Container = styled.div`
	padding: 24px 0;
`;
interface HomeCommunitiesProps {
	communities: IFetchCareerCommunity[];
}

const HomeCommunities = ({ communities }: HomeCommunitiesProps) => {
	const [showCommunityModal, setShowCommunityModal] = useState<boolean>(false);
	const [communityModalData, setCommunityModalData] = useState<IFetchCareerCommunity>();
	const handleCloseCommunityModal = () => {
		setCommunityModalData(undefined);
		setShowCommunityModal(false);
	};
	const handleOpenModal = (careerCommunity: IFetchCareerCommunity) => {
		setCommunityModalData(careerCommunity);
		setShowCommunityModal(true);
	};

	return (
		<HomeCommunitiesContainer>
			<HomeSection
				title="澳洲IT社群 - 悉尼 墨尔本 布里斯班 阿德莱德 堪培拉 珀斯"
				descriptions={[
					'澳洲IT就业情况讨论,岗位内推,行业分析，零基础转码成功经验分享，澳洲IT活动群，移民，IT求职，签证群，各类群组任你选。',
					'悉尼，墨尔本，布里斯班大学新生校友群，加入解锁最新校园资讯，认识志同道合的朋友。',
					'点击下方社区模块，扫码加入我们！'
				]}
				btnTitle="更多社群"
				btnLink="/career-community"
				theme="default"
			>
				<Container>
					<Grid
						container
						columnSpacing={{ tablet: 4, largeLaptop: 6, desktop: 8 }}
						rowSpacing={{ miniMobile: 5, largeLaptop: 6 }}
					>
						{communities.map(community => {
							return (
								<Grid
									item
									mobile={12}
									laptop={6}
									largeLaptop={4}
									key={community.id}
								>
									<PrimeCommunity
										data={community}
										onClick={() => handleOpenModal(community)}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Container>
				{communityModalData && (
					<Modal isVisible={showCommunityModal} handleClose={handleCloseCommunityModal}>
						<ModalContent
							handleClose={handleCloseCommunityModal}
							modalData={communityModalData}
						/>
					</Modal>
				)}
			</HomeSection>
		</HomeCommunitiesContainer>
	);
};

export default HomeCommunities;
