'use client';

import { Grid } from '@mui/material';

import HomeSection from '../components/HomeSection';

import TutorCard from '@/components/Shares/Card/TutorCard';

interface HomeTutorProps {
	teachers: ITeacherCard[];
}

const HomeTutor = ({ teachers }: HomeTutorProps) => {
	const cornerImage = {
		src: '/image/home/tutor.svg',
		alt: 'tutor',
		width: 140,
		height: 82
	};

	return (
		<HomeSection
			title="匠人导师团队 - 求职专家"
			description="超过600位行业一线老师, 拥有多年澳洲IT行业经验, 帮助学生: 简历修改, 模拟面试, 职业规划, 职场指导, 模拟面试, 求职陪跑等. 不管是IT在校生IT毕业生, 还是非IT行业转码, 拥有超过7年的经验, 深耕澳洲求职市场助。从大厂在职导师团队中获得宝贵的学习机会和行业建议，实现您的职业目标。"
			btnTitle="更多导师"
			features={['600+全球导师', '简历修改', '面试指导', '职场规划']}
			btnLink="/instructors"
			cornerImage={cornerImage}
		>
			<Grid
				container
				columnSpacing={{
					tablet: 4,
					largeLaptop: 6,
					desktop: 8
				}}
				rowSpacing={{
					miniMobile: 5,
					largeLaptop: 6
				}}
			>
				{teachers.map(item => {
					return (
						<Grid item mobile={12} laptop={6} largeLaptop={4} key={item.id}>
							<TutorCard
								link={`/instructors/${item.slug}`}
								avatar={(item.avatar && item.avatar.url) || ''}
								name={item.name}
								title={item.title}
								linkedin={item.linkedinUrl}
								description={item.introduction}
								companies={item.preCompany.map(com => com.logo.url)}
							/>
						</Grid>
					);
				})}
			</Grid>
		</HomeSection>
	);
};

export default HomeTutor;
