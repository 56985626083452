import { Grid } from '@mui/material';
import { tail } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import { boxBorder, textEllipsis } from '@/styles/mixin';
import { devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const VideoCover = styled.div`
	cursor: pointer;
	${boxBorder('1px', '1px')}
	margin-bottom: 12px;
	position: relative;
	width: 100%;
	&:before {
		background-image: url('/icon/video-player-icon.svg');
		background-position: center;
		background-repeat: no-repeat;
		content: '';
		height: 44px;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 44px;
		z-index: 1;
	}
`;

const VideoCoverContainer = styled(VideoCover)`
	height: 48vw;
	@media ${devices.mobile} {
		height: 24vw;
	}
	@media ${devices.laptop} {
		height: 15vw;
	}
	@media ${devices.largeLaptop} {
		height: calc(368px * 9 / 16);
		width: 368px;
	}
`;
const VideoCoverImage = styled(Image)`
	height: 100%;
	object-fit: cover;
	width: 100%;
`;
const VideoTitle = styled.h4`
	font-weight: 500;
	${textEllipsis()}
	margin: 16px 0 0;
`;

const SecondaryVideo = ({
	videos,
	setVideo
}: {
	videos: HomeVideo[];
	setVideo: (video: HomeVideo) => void;
}) => {
	return (
		<Grid
			container
			columnSpacing={{
				miniMobile: 0,
				tablet: 4,
				largeLaptop: 4,
				desktop: 6
			}}
			rowSpacing={4}
		>
			{tail(videos).map(video => {
				return (
					<Grid item key={video.id} miniMobile={12} tablet={6} largeLaptop={4}>
						<VideoCoverContainer onClick={() => setVideo(video)}>
							<VideoCoverImage
								src={video.thumbnail}
								alt={video.title}
								unoptimized
								loader={imageLoader}
								width={0}
								height={0}
							/>
						</VideoCoverContainer>
						<Link href={`/video/${video.slug}`}>
							<VideoTitle>{video.title}</VideoTitle>
						</Link>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default SecondaryVideo;
