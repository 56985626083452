import styled from 'styled-components';

import { color, devices } from '@/styles/variables';

const DataContainer = styled.div`
	align-items: center;
	display: grid;
	grid-gap: 14px;
	grid-template-columns: repeat(2, 1fr);
	margin-top: 32px;
	row-gap: 16px;
	@media ${devices.tablet} {
		margin-top: 42px;
		grid-gap: 10px;
		row-gap: 60px;
	}
	@media ${devices.laptop} {
		display: flex;
		justify-content: center;
		margin-top: 40px;
	}
	p {
		margin: 0 0;
	}
`;
const DataItemWrapper = styled.div`
	align-items: center;
	background-color: #fff;
	border: solid 1px #000;
	border-radius: 1px;
	display: flex;
	justify-content: center;
	padding: 14px 20px;
	@media ${devices.tablet} {
		padding: 0;
		background-color: unset;
		border-radius: 0;
		border: unset;
	}
`;
const DataItem = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 12px;

	@media ${devices.tablet} {
		padding: 0 32px;
	}
	@media ${devices.laptop} {
		padding: 0 48px;
	}
	@media ${devices.largeLaptop} {
		padding: 0 64px;
	}
`;
const DataNameMobile = styled.p`
	display: block;
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	text-align:left;
	width 100%;
	@media ${devices.tablet} {
		display: none;
	}
`;
const DataNum = styled.p`
	color: ${color.primaryColor};
	font-size: 28px;
	font-weight: bold;
	font-weight: bold;
	line-height: 1;
	@media ${devices.tablet} {
		font-size: 48px;
	}
`;
const DataName = styled.p`
	display: none;
	@media ${devices.tablet} {
		display: block;
		font-size: 16px;
		font-weight: 500;
		line-height: 16px;
	}
`;
const DataItemSplit = styled.div`
	background-color: ${color.blackColor};
	display: none;
	height: 24px;
	width: 2px;
	@media ${devices.laptop} {
		display: inline-block;
	}
`;

const data: {
	name: string;
	num: number;
}[] = [
	{
		name: '学员人数',
		num: 5000
	},
	{
		name: 'Offer',
		num: 5000
	},
	{
		name: '师资队伍',
		num: 600
	},
	{
		name: '粉丝人数',
		num: 60000
	}
];

const DataCounterBox = () => {
	return (
		<DataContainer>
			{data.map((item, index) => {
				return (
					<DataItemWrapper key={item.name}>
						<DataItem>
							<DataNameMobile>{item.name}</DataNameMobile>
							<DataNum>{item.num.toLocaleString()}+</DataNum>
							<DataName>{item.name}</DataName>
						</DataItem>
						{index < data.length - 1 && <DataItemSplit />}
					</DataItemWrapper>
				);
			})}
		</DataContainer>
	);
};

export default DataCounterBox;
