'use client';

import { Grid } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';

import HomeSection from './components/HomeSection';
import OfferCard from '@/components/Shares/Card/OfferCard';
import Modal from '@/components/Shares/Modal';
import OfferModalContent from '@/components/Shares/ModalContent/OfferModalContent';
import { dotBox } from '@/styles/mixin';

const HomeOfferContainer = styled.div`
	position: relative;
	&:before {
		${dotBox};
		height: 50px;
		position: absolute;
		width: 100%;
	}
`;

const Container = styled.div`
	padding: 24px 0;
`;

interface HomeOfferProps {
	offers: ITestimonialOffer[];
}

const HomeOffer = ({ offers }: HomeOfferProps) => {
	const [showOfferModal, setShowOfferModal] = useState<boolean>(false);
	const [offerModalData, setOfferModalData] = useState<ITestimonialOffer>();
	const handleCloseOfferModal = () => {
		setOfferModalData(undefined);
		setShowOfferModal(false);
	};
	return (
		<HomeOfferContainer>
			<HomeSection
				title="学员Offer榜 - 澳洲IT"
				descriptions={[
					'300+优秀毕业生offer精选展示，往期优秀学员成功入职Atlassian，Amazon，AWS，Deloitte，PWC，Accenture等澳洲或国内一线大厂。',
					'这些Offer成功实例不仅代表了同学们的努力，也是我们IT职业培训质量的承诺。帮助就业全部, 全栈工程师, 产品经理, 数据分析师, 数据工程师, 前端工程师, 后端工程师, DevOps工程师, UI设计师, BA业务分析师, 测试工程师, 金融分析师'
				]}
				btnTitle="更多学员offer"
				btnLink="/offer-board"
				theme="light"
			>
				<Container>
					<Grid
						container
						columnSpacing={{
							miniMobile: 0,
							tablet: 3
						}}
						rowSpacing={3}
					>
						{offers.map(offer => {
							return (
								<Grid
									item
									key={offer.id}
									miniMobile={12}
									tablet={6}
									largeLaptop={3}
								>
									<OfferCard
										offer={offer}
										cover={offer.relatedCompany?.backgroundImg?.url || ''}
										company={{
											logo: offer.relatedCompany?.logo.url || '',
											name: offer.relatedCompany?.name || ''
										}}
										university={
											offer.university?.chineseName || offer.university?.name
										}
										program={offer.program?.name || ''}
										job={offer.jobFunction}
										salary={offer.salary ? `${offer.salary / 1000}k` : '未知'}
										name={offer.name}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Container>
				{offerModalData && (
					<Modal isVisible={showOfferModal} handleClose={handleCloseOfferModal}>
						<OfferModalContent offer={offerModalData} />
					</Modal>
				)}
			</HomeSection>
		</HomeOfferContainer>
	);
};

export default HomeOffer;
