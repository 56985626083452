'use client';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';

import FilterContainer from '@/components/Shares/FilterContainer';
import UpcomingProgramCard from '@/components/Shares/UpComingProgramCard';
import { EProgramCardTagContent, EProgramCardTimeLengthTitle } from '@/constants/program';
import { ETrainingType } from '@/constants/training';
import { devices } from '@/styles/variables';

const UpComingProgramsContainer = styled.div`
	display: none;
	padding-top: 32px;
	@media ${devices.tablet} {
		display: flex;
	}
`;

const UpComingProgramsContainerMobile = styled.div`
	display: flex;
	padding-top: 32px;
	@media ${devices.tablet} {
		display: none;
	}
`;

interface BootcampUpComingProgramsProps {
	type?: ETrainingType;
	programs: [IProgramCard[], IProgramCard[], IProgramCard[]];
}

const BootcampUpComingPrograms = ({ type, programs }: BootcampUpComingProgramsProps) => {
	const [showPrograms, setShowPrograms] = useState<IProgramCard[]>(programs.flat());
	const [tab, setTab] = useState<string>('0');

	const currentMonth = dayjs().month() + 1;
	const months = [
		'最近三个月内',
		`${currentMonth}月`,
		`${(currentMonth + 1) % 12 || 12}月`,
		`${(currentMonth + 2) % 12 || 12}月`
	];

	const programsByTab = [programs.flat(), programs[0], programs[1], programs[2]];
	const handleFilterChange = (value: string) => {
		setTab(value);
		setShowPrograms(programsByTab[Number(value)]);
	};

	const cardTagContent =
		type === ETrainingType.INTERNSHIP
			? EProgramCardTagContent.NEWEST
			: EProgramCardTagContent.AboutToStart;

	const timeLengthTitle =
		type === ETrainingType.INTERNSHIP
			? EProgramCardTimeLengthTitle.PROJECT_TIME_LENGTH
			: EProgramCardTimeLengthTitle.PROGRAM_TIME_LENGTH;

	const isTeachingMethodShow = type !== ETrainingType.INTERNSHIP;

	return (
		<>
			<FilterContainer
				filters={months
					.map((item, index) => ({
						label: item,
						value: `${index}`
					}))
					.filter((item, index) => !isEmpty(programsByTab[index]))}
				value={tab}
				onChange={handleFilterChange}
			/>
			<UpComingProgramsContainer>
				<Grid container spacing={6}>
					{!isEmpty(showPrograms) &&
						showPrograms.map(item => {
							return (
								<Grid key={item.id} item mobile={6} laptop={6} largeLaptop={4}>
									<UpcomingProgramCard
										cardTagContent={cardTagContent}
										timeLengthTitle={timeLengthTitle}
										isTeachingMethodShow={isTeachingMethodShow}
										training={item.training}
										slug={item.slug}
										name={item.name}
										commenceCourseDate={
											type !== ETrainingType.INTERNSHIP
												? item.commenceCourseDate
												: undefined
										}
										city={item.city}
									/>
								</Grid>
							);
						})}
				</Grid>
			</UpComingProgramsContainer>
			<UpComingProgramsContainerMobile>
				<Grid container spacing={2}>
					{!isEmpty(showPrograms) &&
						showPrograms.map(item => {
							return (
								<Grid key={item.id} item mobile={6} laptop={6} largeLaptop={4}>
									<UpcomingProgramCard
										cardTagContent={cardTagContent}
										timeLengthTitle={timeLengthTitle}
										isTeachingMethodShow={isTeachingMethodShow}
										training={item.training}
										slug={item.slug}
										name={item.name}
										commenceCourseDate={
											type !== ETrainingType.INTERNSHIP
												? item.commenceCourseDate
												: undefined
										}
										city={item.city}
									/>
								</Grid>
							);
						})}
				</Grid>
			</UpComingProgramsContainerMobile>
		</>
	);
};

export default BootcampUpComingPrograms;
