import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import Button from '@/components/UI/Button';
import { boxBorder, boxElevation, textEllipsis } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import { getProgramTeachingMethod } from '@/utils/common';
import imageLoader from '@/utils/loader';

const UpComingProgram = styled.div`
	background-color: ${color.whiteColor};
	display: flex;
	flex-direction: column;
	${boxBorder()}
	${boxElevation(4, color.blackColor)}
	padding: 16px 10px;
	width: 100%;
	@media ${devices.tablet} {
		padding: 16px 16px;
	}
`;
const Tag = styled.div`
	align-items: center;
	background-color: ${color.labelBgColorYellow};
	display: flex;
	font-size: 12px;
	gap: 4px;
	line-height: 1;
	${boxBorder('1px', '0px')}
	padding: 4px 6px;
	width: fit-content;
	@media ${devices.tablet} {
		font-size: 14px;
		padding: 4px 8px;
		gap: 6px;
	}
`;
const HotImage = styled(Image)`
	height: 12px;
	width: 9px;
	@media ${devices.tablet} {
		width: 11px;
		height: 15px;
	}
`;
const InfoContainer = styled.div`
	display: none;
	@media ${devices.tablet} {
		border-bottom: dotted 1px ${color.textSecondaryColor};
		border-top: dotted 1px ${color.textSecondaryColor};
		display: flex;
		flex-direction: column;
	}
`;
const InfoItem = styled.div`
	align-items: center;
	border-bottom: dotted 1px ${color.textSecondaryColor};
	display: flex;
	font-size: 14px;
	gap: 6px;
	padding-bottom: 6px;
	padding-top: 6px;
	:last-of-type {
		border-bottom: none;
	}
`;
const InfoContainerMobile = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	@media ${devices.tablet} {
		display: none;
	}
`;
const InfoItemMobile = styled.p`
	color: #333;
	font-size: 12px;
	line-height: 1;
	margin: 0;
	${textEllipsis(1)}
`;
const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
	margin-bottom: 24px;
	margin-top: 8px;
	@media ${devices.tablet} {
		margin-bottom: 20px;
		margin-top: 16px;
	}
`;
const Title = styled.h3`
	font-size: 14px;
	${textEllipsis(2)}
	font-weight: 600;
	height: 32px;
	line-height: 1.14;
	margin: 0 0;
	@media ${devices.tablet} {
		line-height: 1.43;
		height: 68px;
		font-size: 22px;
	}
`;
const TitleTime = styled.p`
	display: none;
	@media ${devices.tablet} {
		display: unset;
		font-size: 14px;
		font-weight: 600;
		margin: 0 0;
	}
`;
const BtnContainer = styled.div`
	display: none;
	@media ${devices.tablet} {
		display: flex;
		justify-content: end;
		margin-top: 16px;
	}
`;
const BtnContainerMobile = styled.div`
	display: flex;
	justify-content: end;
	margin-top: 16px;
	@media ${devices.tablet} {
		display: none;
	}
`;

interface UpcomingProgramCardProps {
	slug: string;
	name: string;
	commenceCourseDate?: string;
	city: {
		name: string;
	};
	training: {
		id: string;
		slug: string;
		timeLength: string;
		level: string;
	};
	cardTagContent: string;
	timeLengthTitle: string;
	isTeachingMethodShow: boolean;
}
const UpcomingProgramCard = ({
	cardTagContent,
	timeLengthTitle,
	isTeachingMethodShow,
	name,
	training,
	commenceCourseDate,
	city
}: UpcomingProgramCardProps) => {
	return (
		<UpComingProgram>
			<Tag>
				<HotImage
					src="/icon/fire.svg"
					alt="fire"
					unoptimized
					loader={imageLoader}
					width={0}
					height={0}
				/>
				{cardTagContent}
			</Tag>
			<TitleContainer>
				<Link href={`/program-course/${training.slug}`}>
					<Title>{name}</Title>
				</Link>
				{commenceCourseDate && (
					<TitleTime>
						开课日期: {dayjs(commenceCourseDate).format('YYYY/MM/DD')}
					</TitleTime>
				)}
			</TitleContainer>
			<InfoContainer>
				<InfoItem>
					<Image
						src="/image/study/recommend-course-duration.svg"
						alt="duration"
						unoptimized
						loader={imageLoader}
						width={14}
						height={14}
					/>
					{timeLengthTitle}: {training.timeLength}
				</InfoItem>
				<InfoItem>
					<Image
						src="/image/study/recommend-course-level.svg"
						alt="duration"
						unoptimized
						loader={imageLoader}
						width={14}
						height={14}
					/>
					难度等级: {training.level}
				</InfoItem>
				{isTeachingMethodShow && (
					<InfoItem>
						<Image
							src="/image/common/type.svg"
							alt="type"
							unoptimized
							loader={imageLoader}
							width={14}
							height={14}
						/>
						<span>授课方式：{getProgramTeachingMethod(city.name)}</span>
					</InfoItem>
				)}
			</InfoContainer>
			<InfoContainerMobile>
				<InfoItemMobile>课程时长：{training.timeLength}</InfoItemMobile>
				<InfoItemMobile>难度等级：{training.level}</InfoItemMobile>
				<InfoItemMobile>授课方式：{getProgramTeachingMethod(city.name)}</InfoItemMobile>
			</InfoContainerMobile>
			<BtnContainer>
				<Link href={`/program-course/${training.slug}`}>
					<Button color="default">查看详情</Button>
				</Link>
			</BtnContainer>
			<BtnContainerMobile>
				<Link href={`/program-course/${training.slug}`}>
					<Button
						color="default"
						theme="text"
						style={{
							width: '22px',
							height: '22px',
							padding: 0,
							fontSize: '12px',
							border: '1px solid #000',
							borderRadius: '1px'
						}}
					>
						→
					</Button>
				</Link>
			</BtnContainerMobile>
		</UpComingProgram>
	);
};
export default UpcomingProgramCard;
