import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { isNull, isNumber } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { color, devices } from '@/styles/variables';

const Container = styled.div<{ responsive: boolean }>`
	.MuiToggleButtonGroup-root {
		flex-wrap: wrap;
		flex-direction: ${props => (props.responsive ? 'column' : 'row')};
		@media ${devices.laptop} {
			flex-direction: row;
		}
	}
	& .MuiToggleButtonGroup-grouped {
		border-radius: 2px;
		&:not(:first-of-type) {
			border-color: #000;
		}
		&.MuiToggleButton-root {
			background-color: #fff;
			border: solid 1px #000;
			min-width: 74px;
			padding: 4px 8px;
			color: #000;
		}
		&.Mui-selected {
			background-color: #000;
			color: #fff;
		}
	}
`;

const Count = styled.span`
	color: ${color.primaryColor};
	font-size: 14px;
	padding-left: 2px;
`;

interface FilterContainerProps {
	filters: {
		label: string;
		value: string;
		count?: number;
	}[];
	value?: string;
	responsive?: boolean;
	onChange: (value: string) => void;
}

const FilterContainer = ({ filters, value = '', onChange, responsive }: FilterContainerProps) => {
	const handleChange = (event: React.MouseEvent<HTMLElement>, selectedValue: string) => {
		if (!isNull(selectedValue)) {
			onChange(selectedValue);
		}
	};

	return (
		<Container responsive={responsive || false}>
			<ToggleButtonGroup value={value} exclusive onChange={handleChange}>
				{filters?.map(item => (
					<ToggleButton value={item.value} key={item.value}>
						{item.label}
						{isNumber(item.count) ? <Count>{item.count}</Count> : null}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</Container>
	);
};

export default FilterContainer;
