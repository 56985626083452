import { LOGO_URL } from './common';

export const DEFAULT_METADATA = {
	title: '澳洲匠人学院',
	description:
		'澳洲最好的IT培训求职平台, 澳洲IT就业, 澳洲IT, 澳洲求职, 来自Google, Amazon, Atlassian, Microsoft等澳洲一线公司的导师, 帮助学生拿到offer。服务有, IT培训, 求职辅导, 大学课程辅导, 补习, 面试简历指导, 澳洲留学移民, 找工作',
	keywords:
		'澳洲求职, 布里斯班, 悉尼, 墨尔本, 阿德莱德, 塔斯马尼亚, 堪培拉 ,澳洲最大IT找工作平台, 澳洲IT就业, 澳洲IT, IT培训, 大学补习, 澳洲IT就业培训方案, 技能培训,求职服务,面试简历指导,大学辅导,匠人活动,澳洲IT匠人圈',
	image: LOGO_URL
};

export default DEFAULT_METADATA;

export const SIGN_IN_PAGE_METADATA = {
	description:
		'欢迎登录匠人学院, 开启您的IT职业成长旅程。通过个性化课程、顶级导师指导和求职支持服务, 助力澳洲IT求职者快速实现职业目标。登录后可访问专属学习资源、课程进度及职业发展工具',
	keywords:
		'登录匠人学院, IT职业培训, 澳洲IT就业支持, 悉尼IT学习平台, 墨尔本求职服务, 澳洲IT技能提升, IT课程学习, 简历优化, 面试辅导, 澳洲职业规划'
};

export const SIGN_UP_PAGE_METADATA = {
	description:
		'注册匠人学院, 开启IT职业新篇章! 创建学习账户, 解锁来自Google、Amazon等名企导师的实战课程和求职支持。提升技能, 优化简历, 获取行业认证, 助您快速找到理想工作, 迈向成功的IT职业生涯',
	keywords:
		'匠人学院注册, 澳洲IT培训, IT求职平台, 创建学习账户, 悉尼IT就业辅导, 墨尔本IT技能提升, 澳洲IT课程, 简历优化, 面试指导, 澳洲留学移民, IT职业发展'
};

export const FORGET_PAGE_METADATA = {
	description:
		'如果您忘记了匠人学院账户的密码，只需输入您注册时使用的邮箱地址，我们将向您的邮箱发送密码重置链接。我们的密码恢复流程简单、安全，确保您的账户能够迅速恢复。通过完成此流程，您将能继续访问您的学习资料和参与职业发展的各项活动，轻松找回学习进度。',
	keywords:
		'匠人学院, 忘记密码, 密码重置, 账户恢复, 学习账户, 重设密码, 注册邮箱, 密码找回, 安全登录, 账户安全, JR Academy'
};

export const WECHAT_LOGIN_PAGE_METADATA = {
	description:
		'欢迎登录匠人学院！通过微信快速登录，您可以轻松注册并绑定账户，开始个性化学习之旅。只需扫描二维码，即可快速完成微信认证，享受全面的学员支持与服务。无论是课程进度查询、学习资源获取，还是职业发展规划，微信登录都让一切变得更加便捷、安全。',
	keywords:
		'匠人学院, 微信登录, 微信注册, 账户绑定, 快速登录, 扫码登录, 微信认证, 用户注册, 学习平台账户, 个人化学习, 在线学习平台, 学员支持, 便捷登录, JR Academy'
};
