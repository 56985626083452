import { checkCSR } from './check';
import { LOGO_URL } from '@/constants/common';
import { isProd } from '@/constants/env';
import { getWechatSignature } from '@/services/wechat';

export default async (config?: { title?: string; desc?: string; imgUrl?: string }) => {
	if (checkCSR() && isProd) {
		const wxSignature = await getWechatSignature(
			encodeURIComponent(window.location.href.split('#')[0])
		);
		if (typeof wx !== 'undefined') {
			wx.config({
				debug: false,
				appId: wxSignature.appId,
				timestamp: wxSignature.timestamp,
				nonceStr: wxSignature.nonceStr,
				signature: wxSignature.signature,
				jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage']
			});
			wx.ready(() => {
				const shareConfig = {
					title: config?.title || document.title,
					link: window.location.href,
					desc:
						config?.desc ||
						(document.querySelector('meta[name="description"]') as HTMLMetaElement)
							?.content ||
						'',
					imgUrl:
						config?.imgUrl ||
						(document.querySelector('meta[property="og:image"]') as HTMLMetaElement)
							?.content ||
						LOGO_URL
				};
				// Share by message
				wx.onMenuShareAppMessage(shareConfig);
				// Share to moments
				wx.onMenuShareTimeline(shareConfig);
			});
		}
	}
};
