import HomeSection from './components/HomeSection';
import DataCounterBox from '@/components/Shares/Box/DataCounterBox';
import WorkedAtBox from '@/components/Shares/Box/WorkedAtBox';

interface HomeCounterProps {
	employedAt: string[];
}
const HomeCounter = ({ employedAt }: HomeCounterProps) => {
	return (
		<HomeSection theme="default">
			<WorkedAtBox title="我们的毕业生入职于顶尖科技公司" logos={employedAt} />
			<DataCounterBox />
		</HomeSection>
	);
};

export default HomeCounter;
