'use client';

import Script from 'next/script';
import React, { useEffect } from 'react';

import { LOGO_URL } from '@/constants/common';
import { DEFAULT_METADATA } from '@/constants/meta';
import setWechatShareConfig from '@/utils/setWechatShareConfig';

const WechatShare = ({ config = {} }: { config?: IWechatShareConfig }) => {
	useEffect(() => {
		setWechatShareConfig({
			title: DEFAULT_METADATA.title,
			desc: DEFAULT_METADATA.description,
			...config,
			imgUrl: config.imgUrl || LOGO_URL
		});
	}, []);
	return (
		<Script
			src="https://res.wx.qq.com/open/js/jweixin-1.2.0.js"
			type="text/javascript"
			strategy="afterInteractive"
		/>
	);
};

export default WechatShare;
