'use client';

import styled from 'styled-components';

import HomeSection from '../components/HomeSection';

import FeedbackCard from '@/components/Shares/Card/FeedbackCard';
import { dotBox } from '@/styles/mixin';
import { devices } from '@/styles/variables';

const HomeFeedbackContainer = styled.div`
	position: relative;
	&:before {
		${dotBox};
		display: none;
		@media ${devices.laptop} {
			display: block;
			right: 120px;
			height: 50px;
			position: absolute;
			top: 48px;
			width: 278px;
		}
		@media ${devices.largeLaptop} {
			top: 80px;
		}
	}
`;

const FeedbackContainer = styled.div`
	align-items: center;
	display: flex;
	flex-wrap: nowrap;
	gap: 26px;
	margin-top: 58px;
	max-width: 1440px;
	overflow-y: hidden;
	padding-bottom: 8px;
	padding-left: 8px;
	right: 16px;
	@media ${devices.laptop} {
		right: 120px;
		overflow: visible;
		flex-wrap: wrap;
		padding-left: 0;
	}
	::-webkit-scrollbar {
		display: none;
	}
`;

interface HomeFeedbackProps {
	feedbacks: IFeedback[];
}

let counter = 0;
const CardColors = ['#fffbfb', '#fffae5', '#f5fcff', '#eff2ff'];
const getColor = () => {
	counter += 1;
	return CardColors[counter % 3];
};

const HomeFeedback = ({ feedbacks }: HomeFeedbackProps) => {
	return (
		<HomeFeedbackContainer>
			<HomeSection
				title="学员分享 - 澳洲IT 求职培训分享"
				description="匠人学员分享了通过匠人训练营的实际工作技能培训和项目实践的好评，成功实现澳洲IT职业发展， 掌握最新IT技术，面试经验及获取offer，显著提高了在澳洲IT求职市场的竞争力。"
				theme="default"
			>
				<FeedbackContainer>
					{feedbacks.map(feedback => {
						return (
							<FeedbackCard
								key={feedback.id}
								rating={feedback.rating}
								userName={`${feedback.userName}`}
								avatar={feedback.userAvatar}
								isAnonymous={feedback.isAnonymous}
								content={feedback.description}
								programName={`${feedback.programName}学员`}
								cardColor={getColor()}
							/>
						);
					})}
				</FeedbackContainer>
			</HomeSection>
		</HomeFeedbackContainer>
	);
};

export default HomeFeedback;
