import { tail } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import { textEllipsis } from '@/styles/mixin';
import imageLoader from '@/utils/loader';

const VideoContainer = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	gap: 8px;
	height: 140px;
	min-width: 210px;
	width: 210px;
`;
const VideoCoverContainer = styled.div`
	border: 1px solid #000;
	border-radius: 1px;
	height: 118px;
	position: relative;
	width: 100%;
	&:before {
		background-image: url('/icon/video-player-icon.svg');
		background-position: center;
		background-repeat: no-repeat;
		border-radius: 50%;
		content: '';
		height: 30px;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 30px;
		z-index: 1;
	}
`;
const VideoCoverImage = styled(Image)`
	height: 100%;
	object-fit: cover;
	width: 100%;
`;
const Title = styled.p`
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	margin: 0;
	${textEllipsis(1)}
`;

const SecondaryVideoMobile = ({
	videos,
	setVideo
}: {
	videos: HomeVideo[];
	setVideo: (video: HomeVideo) => void;
}) => {
	return tail(videos).map(video => {
		return (
			<VideoContainer>
				<VideoCoverContainer onClick={() => setVideo(video)}>
					<VideoCoverImage
						src={video.thumbnail}
						alt={video.title}
						unoptimized
						loader={imageLoader}
						width={0}
						height={0}
					/>
				</VideoCoverContainer>
				<Link href={`/video/${video.slug}`}>
					<Title>{video.title}</Title>
				</Link>
			</VideoContainer>
		);
	});
};

export default SecondaryVideoMobile;
