'use client';

import { Grid } from '@mui/material';
import styled from 'styled-components';

import VideoCard from '../../Shares/VideoCard';

import HomeSection from './components/HomeSection';
import { dotBox } from '@/styles/mixin';
import { devices } from '@/styles/variables';

const HomeLecturesContainer = styled.div`
	position: relative;
	&:before {
		${dotBox};
		display: none;
		height: 230px;
		position: absolute;
		right: 0;
		top: 80px;
		width: 278px;
		@media ${devices.mobile} {
			display: block;
		}
	}
`;
const CardGridContainer = styled.div`
	display: none;
	@media ${devices.tablet} {
		display: flex;
	}
`;
const CardGridContainerMobile = styled.div`
	display: flex;
	@media ${devices.tablet} {
		display: none;
	}
`;

interface HomeLecturesProps {
	videos: TVideoCard[];
	setVideo: (video: TVideoCard) => void;
}

const HomeLectures = ({ videos, setVideo }: HomeLecturesProps) => {
	return (
		<HomeLecturesContainer>
			<HomeSection
				title="匠人TV - 澳洲IT 求职分享"
				description="提供深入了解澳洲IT领域特定主题的机会，由资深行业专家主持，涵盖职场，技术趋势、实践和应用。参加讲座，您将与专家互动，获取见解，扩展知识，促进职业发展，助力职业发展。无论您是初学者还是专业人士，都能从中获益匪浅。"
				btnTitle="更多专题视频"
				features={['大学选课', '澳洲求职故事', '澳洲职场干货', '求职技巧']}
				btnLink="/videos"
			>
				{videos.length === 0 && '暂无数据'}
				{videos.length > 0 && (
					<>
						<CardGridContainer>
							<Grid
								container
								columnSpacing={{
									miniMobile: 0,
									tablet: 4
								}}
								rowSpacing={4}
							>
								{videos.map(video => {
									return (
										<Grid
											item
											key={video.id}
											miniMobile={12}
											tablet={6}
											largeLaptop={3}
										>
											<VideoCard
												id={video.id}
												slug={video.slug}
												thumbnail={video.thumbnail}
												title={video.title}
												duration={video.duration}
												youtubePublishTime={video.youtubePublishTime}
												handleClick={() => setVideo(video)}
											/>
										</Grid>
									);
								})}
							</Grid>
						</CardGridContainer>
						<CardGridContainerMobile>
							<Grid
								container
								columnSpacing={{
									miniMobile: 2,
									tablet: 2
								}}
								rowSpacing={2}
							>
								{videos.map(video => {
									return (
										<Grid
											item
											key={video.id}
											miniMobile={6}
											tablet={6}
											largeLaptop={3}
										>
											<VideoCard
												id={video.id}
												slug={video.slug}
												thumbnail={video.thumbnail}
												title={video.title}
												duration={video.duration}
												youtubePublishTime={video.youtubePublishTime}
												handleClick={() => setVideo(video)}
											/>
										</Grid>
									);
								})}
							</Grid>
						</CardGridContainerMobile>
					</>
				)}
			</HomeSection>
		</HomeLecturesContainer>
	);
};

export default HomeLectures;
