'use client';

import { Grid } from '@mui/material';
import styled from 'styled-components';

import HomeSection from './components/HomeSection';
import MeetupCard from '@/components/Shares/Card/MeetupCard';
import { JR_EVENT_TYPE, MEETUP_TYPE } from '@/constants/meetup';

const Content = styled.div`
	.MuiGrid-root {
		margin-top: 0 !important;
	}
`;

const HomeActivities = ({ meetups }: { meetups: IMeetup[] }) => {
	return (
		<HomeSection
			theme="light"
			title="匠人学院活动 - 澳洲求职讲座面试技巧 "
			description="我们已成功举办200+线上线下求职面试活动，加入我们，和100+IT行业顶尖专家交流沟通，结识10000+志同道合的澳洲IT圈伙伴。"
			btnTitle="更多精彩活动"
			btnLink="/events"
			hot
		>
			<Content>
				<Grid
					container
					columnSpacing={{
						miniMobile: 0,
						tablet: 4
					}}
					rowSpacing={4}
					style={{
						marginTop: '30px'
					}}
				>
					{meetups?.map(item => {
						return (
							<Grid item key={item.id} miniMobile={12} tablet={6} largeLaptop={3}>
								<MeetupCard
									key={item.id}
									id={item.id}
									imageUrl={(item.image && item.image.url) || ''}
									title={item.title}
									startTime={item.period.start}
									endTime={item.period.end}
									meetupType={item.meetupType as MEETUP_TYPE}
									liveStreaming={item.liveStreaming}
									address={item.address}
									tickets={item.tickets}
									remainingNum={item.remainingNum || 0}
									totalNum={item.totalNum || 0}
									jrEventType={item.jrEventType as JR_EVENT_TYPE}
									applied={item.applied}
									isLive={item.isLive}
									zoomMeetingId={item.zoomMeetingId}
								/>
							</Grid>
						);
					})}
				</Grid>
			</Content>
		</HomeSection>
	);
};

export default HomeActivities;
