export const homeInstructors = [
	{
		imageSrc: '/image/home/instructors/guang.webp',
		link: '/instructors/g-x'
	},
	{
		imageSrc: '/image/home/instructors/ally_tang.webp',
		link: '/instructors/ally-tang'
	},
	{
		imageSrc: '/image/home/instructors/ray_ma.webp',
		link: '/instructors/ray-ma'
	},
	{
		imageSrc: '/image/home/instructors/justin_hu.webp',
		link: '/instructors/justin-hu-1'
	},
	{
		imageSrc: '/image/home/instructors/ling_tang.webp',
		link: '/instructors/ling-tang'
	},
	{
		imageSrc: '/image/home/instructors/liji_yu.webp',
		link: '/instructors/liji-yu'
	}
];

export default {};
