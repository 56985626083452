import { useEffect, useState } from 'react';

const useCountdown = (interval = 60) => {
	const [countdown, setCountdown] = useState<number | null>(null);

	useEffect(() => {
		// 如果 countdown 是 null 或者已经是 0，则不做任何操作。
		if (countdown === null || countdown === 0) return;

		// 启动定时器
		const timer = setInterval(() => {
			setCountdown(currentCountdown => {
				// 如果当前倒计时大于 0，则减少 1
				if (currentCountdown !== null && currentCountdown > 0) {
					return currentCountdown - 1;
				}

				// 如果当前倒计时已经到 0 或者是 null，清理定时器并返回 0
				clearInterval(timer);
				return 0;
			});
		}, 1000);

		// 清理函数
		// eslint-disable-next-line consistent-return
		return () => clearInterval(timer);
	}, [countdown]);

	const startCountdown = () => {
		setCountdown(interval);
	};

	return { countdown, startCountdown };
};

export default useCountdown;
