import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import { boxBorder, textEllipsis } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import { getTimeFromNow } from '@/utils/date';
import imageLoader from '@/utils/loader';

const VideoContainer = styled.div`
	box-sizing: content-box;
	display: flex;
	flex-direction: column;
	gap: 4px;
	max-width: 100%;
	width: 100%;
	@media ${devices.largeLaptop} {
		gap: 12px;
		max-width: 278px;
		width: fit-content;
	}
`;
const VideoCoverContainer = styled.div`
	${boxBorder('1px', '1px')}
	cursor: pointer;
	height: 22vw;
	position: relative;
	width: 100%;
	@media ${devices.tablet} {
		height: 26vw;
	}
	@media ${devices.laptop} {
		height: 11vw;
	}
	@media ${devices.largeLaptop} {
		height: calc(278px * 9 / 16);
		width: 278px;
	}
`;
const VideoCoverImage = styled(Image)`
	height: 100%;
	object-fit: cover;
	width: 100%;
`;
const VideoDuration = styled.div`
	align-items: center;
	background-color: #333;
	border-radius: 1px;
	bottom: 4px;
	color: ${color.whiteColor};
	display: flex;
	font-size: 12px;
	gap: 4px;
	left: 4px;
	padding: 1px 4px;
	@media ${devices.laptop} {
		padding: 2px 6px;
	}
	position: absolute;
`;
const Title = styled.h3`
	font-size: 14px;
	font-weight: 500;
	${textEllipsis(2)}
	height: 40px;
	margin: 0 0;
	width: 100%;
	@media ${devices.laptop} {
		font-weight: 600;
	}
`;
const Time = styled.div`
	color: #666;
	font-size: 12px;
	line-height: 1;
`;

interface VideoCardProps {
	id: string;
	slug: string;
	thumbnail: string;
	title: string;
	duration: string;
	youtubePublishTime: string;
	handleClick?: () => void;
	showPublishTime?: boolean;
}

const VideoCard = ({
	id,
	slug,
	thumbnail,
	title,
	duration,
	youtubePublishTime,
	handleClick,
	showPublishTime = true
}: VideoCardProps) => {
	return (
		<VideoContainer key={id}>
			<VideoCoverContainer>
				<VideoCoverImage
					src={thumbnail}
					alt={title}
					unoptimized
					loader={imageLoader}
					width={0}
					height={0}
					onClick={handleClick}
				/>
				<VideoDuration>
					<Image
						src="/icon/video-play-small.svg"
						alt="play"
						unoptimized
						loader={imageLoader}
						width={8}
						height={12}
					/>
					{duration}
				</VideoDuration>
			</VideoCoverContainer>
			<Link href={`/video/${slug}`} title={title}>
				<Title>{title}</Title>
			</Link>
			{showPublishTime && <Time>{getTimeFromNow(youtubePublishTime)}</Time>}
		</VideoContainer>
	);
};

export default VideoCard;
