import {
	DEVELOPMENT_BASE_URL,
	DEVELOPMENT_JOBPIN_URL,
	DEVELOPMENT_LEARN_URL,
	PRODUCTION_BASE_URL,
	PRODUCTION_JOBPIN_URL,
	PRODUCTION_LEARN_URL,
	UAT_BASE_URL,
	UAT_JOBPIN_URL,
	UAT_LEARN_URL
} from '@/constants/apiUrl';
import { PRODUCTION, UAT } from '@/constants/env';

export const getHomeUrl = () => {
	const environment = process.env.NODE_ENV;
	if (environment === PRODUCTION) {
		if (process.env.NEXT_PUBLIC_NEXT_ENV === UAT) return UAT_BASE_URL;
		return PRODUCTION_BASE_URL;
	}
	return DEVELOPMENT_BASE_URL;
};

export const getLearnUrl = () => {
	const environment = process.env.NODE_ENV;
	if (environment === PRODUCTION) {
		if (process.env.NEXT_PUBLIC_NEXT_ENV === UAT) return UAT_LEARN_URL;
		return PRODUCTION_LEARN_URL;
	}
	return DEVELOPMENT_LEARN_URL;
};

export const getJobpinUrl = () => {
	const environment = process.env.NODE_ENV;
	if (environment === PRODUCTION) {
		if (process.env.NEXT_PUBLIC_NEXT_ENV === UAT) return UAT_JOBPIN_URL;
		return PRODUCTION_JOBPIN_URL;
	}
	return DEVELOPMENT_JOBPIN_URL;
};
/**
 * @param {string} url
 * @returns {URL}
 */
export const getValidUrl = (url: string) => {
	try {
		const temp = new URL(url);
		return temp;
	} catch (e) {
		throw new Error('Invalid URL: URL must be a full URL, not a relative path');
	}
};

export const getVideoUrl = (youtubeId?: string, bilibiliId?: string) => {
	if (youtubeId) {
		return `https://www.youtube.com/embed/${youtubeId}?rel=0&amp;showinfo=0&amp;autoplay=1`;
	}
	if (bilibiliId) {
		return `https://player.bilibili.com/player.html?bvid=${bilibiliId}`;
	}
	return '';
};

export const getFullUrl = (url: string = '') => {
	if (url.startsWith('http')) {
		return url;
	}
	return `https://${url}`;
};

export const JOBPIN_URL = {
	HOMEPAGE: getJobpinUrl(),
	JOB_PUBLISH: `${getJobpinUrl()}/talent`
};

export default {
	getLearnUrl,
	getHomeUrl
};
