'use client';

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import NavArrow from './NavArrow';
import ScrollToUp from './ScrollToTop';
import { color, devices } from '@/styles/variables';

const MenuSlideContainer = styled.div`
	align-items: center;
	background-color: ${color.whiteColor};
	border: solid 1px #000;
	border-radius: 1px;
	/* display: flex; */
	//hide the side menu temporarily
	display: none;
	flex-direction: column;
	position: fixed;
	right: 45px;
	z-index: 10;
	@media ${devices.tablet} {
		width: 144px;
		bottom: 85px;
	}
`;

const TopContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
`;

const Divider = styled.div`
	background-color: #eaeaea;
	height: 1px;
	width: 75%;
`;

const Text = styled.span<{ inView: boolean }>`
	color: ${props => (props.inView ? color.primaryColor : color.blackColor)};
	cursor: pointer;
	height: 16px;
	line-height: 1;
	margin: 0;
	&:hover {
		color: ${color.primaryColor};
	}
`;

const ItemContainer = styled.div<{ isOpen: boolean }>`
	align-items: center;
	display: ${props => (props.isOpen ? 'flex' : 'none')};
	flex-direction: column;
`;

const ContentContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 48px;
	padding: 16px 21px 0;
	width: 100%;
`;

interface Section {
	id: string;
	text: string;
}

interface NavigationButtonProps {
	sections: Section[];
}

const MenuSlideCombine: React.FC<NavigationButtonProps> = ({ sections }) => {
	const [isContentOpen, setIsContentOpen] = useState<boolean>(false);
	const [activeSection, setActiveSection] = useState<string>('');
	const observer = useRef<IntersectionObserver | null>(null);

	const toggleContent = () => {
		setIsContentOpen(!isContentOpen);
	};

	const navigateToSection = (sectionId: string) => {
		const element = document.querySelector(`#${sectionId}`);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const handleIntersection = (entries: IntersectionObserverEntry[]) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				setActiveSection(entry.target.id);
			}
		});
	};

	useEffect(() => {
		observer.current = new IntersectionObserver(handleIntersection, {
			threshold: 0.5
		});

		sections.forEach(section => {
			const target = document.getElementById(section.id);
			if (target) {
				observer.current!.observe(target);
			}
		});

		return () => {
			if (observer.current) {
				observer.current.disconnect();
			}
		};
	}, [sections]);

	return (
		<MenuSlideContainer>
			<TopContainer>
				<NavArrow isContentOpen={isContentOpen} toggleContentOpen={toggleContent} />
				<ItemContainer isOpen={isContentOpen}>
					{sections.map(({ id, text }) => (
						<React.Fragment key={id}>
							<Divider />
							<ContentContainer>
								<Text
									inView={activeSection === id}
									onClick={() => navigateToSection(`${id}`)}
								>
									{text}
								</Text>
							</ContentContainer>
						</React.Fragment>
					))}
				</ItemContainer>
				<Divider />
			</TopContainer>
			<ScrollToUp />
		</MenuSlideContainer>
	);
};

export default MenuSlideCombine;
