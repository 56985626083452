'use client';

import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { color } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Bottom = styled.div`
	cursor: pointer;
	display: flex;
	height: 48px;
	justify-content: center;
	padding: 16px 21px 0;
	width: 100%;
`;

const Text = styled.span`
	color: ${color.blackColor};
	line-height: 1;
	margin: 0 0 0 5px;
	padding: 0 0 0 3px;
`;

const ScrollUp = () => {
	const [isVisible, setIsVisible] = useState(false);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const handleScroll = () => {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		setIsVisible(scrollTop > window.innerHeight);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		isVisible && (
			<Bottom onClick={scrollToTop}>
				<Image
					src="/icon/back-to-top.svg"
					alt="success"
					unoptimized
					loader={imageLoader}
					width={11}
					height={16}
				/>
				<Text>返回顶部</Text>
			</Bottom>
		)
	);
};

export default ScrollUp;
