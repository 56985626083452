'use client';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import styled from 'styled-components';

import HomeSection from './components/HomeSection';
import CourseCard from '@/components/Shares/CourseCard';
import TRAINING_TYPE_LABEL, { ETrainingType } from '@/constants/training';
import { dotBox } from '@/styles/mixin';
import { devices } from '@/styles/variables';

const HomeTrainingContainer = styled.div`
	position: relative;
	&:before {
		${dotBox};
		background-image: radial-gradient(circle, white 1px, transparent 1px);
		display: none;
		height: 230px;
		position: absolute;
		right: 0;
		top: 130px;
		width: 278px;
		@media ${devices.tablet} {
			display: block;
		}
	}
	&:after {
		${dotBox};
		background-image: radial-gradient(circle, white 1px, transparent 1px);
		height: 230px;
		left: 0;
		position: absolute;
		top: 526px;
		width: 278px;
	}
`;

const StyledGrid = styled(Grid)`
	@media ${devices.tablet} {
		padding-bottom: 32px;
	}
`;
const Content = styled.div`
	.MuiGrid-root {
		margin-top: -14px;
		@media ${devices.tablet} {
			margin-top: 0;
		}
	}
`;

interface HomeTrainingsProps {
	trainings: TrainingCard[];
}

const HomeTrainings = ({ trainings }: HomeTrainingsProps) => {
	return (
		<HomeTrainingContainer>
			<HomeSection
				title="训练营 - 澳洲IT就业"
				description="Bootcamps通过独创P3项目(Handson, Real world Project) 贯穿Web全栈班, DevOps班, BA实习等, 帮助学生解决没有工作经验求职问题. 全方位培养求职就业能力, 从项目经验, 实习经验, 工作经验, 技术技能, 求职面试能力等入手, 全方位培养同学答道 Job Ready能力. 涵盖Full-stack, DevOps, Cloud Engineer, AI Engineer, Big Data, Data Scientist, Agile Project Management等多个领域。"
				features={[
					'获得商业项目经验',
					'匠人元宇宙学习',
					'面向澳洲IT求职市场',
					'团队经验+实习经验'
				]}
				btnTitle="更多匠人训练营"
				btnLink="/bootcamp"
				theme="dark"
			>
				<Content>
					<StyledGrid
						container
						columnSpacing={{
							miniMobile: 0,
							tablet: 4
						}}
						rowSpacing={4}
					>
						{trainings &&
							trainings.map(training => {
								return (
									<Grid
										item
										key={training.id}
										miniMobile={12}
										tablet={6}
										largeLaptop={3}
									>
										<CourseCard
											type={training.type as ETrainingType}
											cardLabel={
												TRAINING_TYPE_LABEL[training.type as ETrainingType]
											}
											title={training.title}
											description={training.description}
											startDate={
												training.upcomingProgramStartDate
													? `${dayjs(
															training.upcomingProgramStartDate
													  ).format('YYYY/MM/DD')}`
													: ''
											}
											duration={training.duration}
											num={training.enrollmentCount}
											level={training.level}
											slug={training.slug}
										/>
									</Grid>
								);
							})}
					</StyledGrid>
				</Content>
			</HomeSection>
		</HomeTrainingContainer>
	);
};

export default HomeTrainings;
