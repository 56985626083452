import { EGa4Btn, EGa4Event, EVENT, EVENT_CATEGORY } from '@/constants/ga4';

/**
 * Send GA4 Event
 */

export const sendGA4Event = (eventConfig: IGa4EventConfig) => {
	if (!window.gtag) {
		return;
	}
	window.gtag(EVENT, eventConfig.eventName, eventConfig.option);
};

export const sendGA4GlobalPopupEvent = (eventName: EGa4Event, pathname: string, value?: string) => {
	sendGA4Event({
		eventName,
		option: {
			event_category: EVENT_CATEGORY.MARKETING_SALES_CONSULTATION,
			pathname,
			...(value ? { sales_name: value } : {})
		}
	});
};

export const sentGA4BtnEvent = (btnName: EGa4Btn, pathname: string) => {
	sendGA4Event({
		eventName: EGa4Event.CLICK_BTN,
		option: {
			btn_name: btnName,
			pathname
		}
	});
};

export default sendGA4Event;
