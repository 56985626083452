import { useEffect } from 'react';

import MeetupWebSocketManager from '@/utils/webSocket/meetupWebsocketManager';

const useSocketMeetupStatus = () => {
	useEffect(() => {
		const webSocketManager = new MeetupWebSocketManager();
		webSocketManager.listenToMeetupsLiveStatus();

		return () => {
			webSocketManager.disconnect();
		};
	}, []);
};

export default useSocketMeetupStatus;
