export const EVENT = 'event';

export enum EGa4Event {
	CLICK_BTN = 'click_btn',
	CLICK_LEAVE_MESSAGE_BTN_GLOBAL_POPUP = 'click_leave_message_btn_global_popup',
	CLICK_META_OFFICE_BTN_GLOBAL_POPUP = 'click_meta_office_btn_global_popup',
	SUBMIT_MESSAGE_GLOBAL_POPUP = 'submit_message_global_popup',
	SUBMIT_META_OFFICE_GLOBAL_POPUP = 'submit_meta_office_global_popup',
	CLICK_META_OFFICE_GET_IN_TOUCH_POPUP = 'click_meta_office_get_in_touch_popup'
}

export enum EGa4Btn {
	GLOBAL_POPUP = 'global_popup',
	HOME_HERO = 'home_hero',
	HOME_META = 'home_meta',
	GET_IN_TOUCH_META_OFFICE = 'get_in_touch_meta_office'
}

export const EVENT_CATEGORY = {
	MARKETING_SALES_CONSULTATION: 'marketing_sales_consultation'
};

export default {};
