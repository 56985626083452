'use client';

import styled from 'styled-components';

import HomeSection from './components/HomeSection';
import BootcampUpComingPrograms from '@/components/Pages/BootcampPage/components/BootcampUpComingPrograms';
import { dotBox } from '@/styles/mixin';

const HomeUpComingProgramsContainer = styled.div`
	position: relative;
	&:before {
		${dotBox};
		height: 230px;
		position: absolute;
		top: 322px;
		width: 100%;
	}
`;

interface HomeUpComingProgramsProps {
	programs: [IProgramCard[], IProgramCard[], IProgramCard[]];
}

const HomeUpComingPrograms = ({ programs }: HomeUpComingProgramsProps) => {
	return (
		<HomeUpComingProgramsContainer>
			<HomeSection
				title="近期开课 - IT技能提升及项目实践求职辅导"
				description="现已开放近期课程报名，提供高质量的IT培训和实习机会，IT培训课程涵盖IT求职辅导,数据分析，编程入门，WordPress项目实训，Devops项目实战等多体系课程，帮助你在职业生涯中获得竞争优势，获取Offer找到工作。"
				features={['澳洲IT就业', '工作经验', '简历面试提升', '拿Offer']}
				btnTitle="更多匠人课程"
				btnLink="/program-courses"
			>
				<BootcampUpComingPrograms programs={programs} />
			</HomeSection>
		</HomeUpComingProgramsContainer>
	);
};

export default HomeUpComingPrograms;
