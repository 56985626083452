import { Socket, io } from 'socket.io-client';

import { getApiUrl } from '../request';

import { ESocketMeetupEventName } from '@/constants/socket';
import store from '@/store';
import { updateMeetupLiveStatus } from '@/store/slices/socketSlice';

class MeetupWebSocketManager {
	private socket: Socket;

	constructor() {
		this.socket = io(`${getApiUrl()}/meetups`);
	}

	public listenToMeetupsLiveStatus(): void {
		this.socket.on(ESocketMeetupEventName.STATUS_UPDATED, data => {
			store.dispatch(updateMeetupLiveStatus(data));
		});
	}

	public disconnect(): void {
		this.socket.disconnect();
	}
}

export default MeetupWebSocketManager;
