'use client';

import styled from 'styled-components';

import ReactPlayerWistia from '@/components/Shares/ReactPlayerWistia';
import { stripeBg } from '@/styles/mixin';
import { devices } from '@/styles/variables';

const VideoContainer = styled.div`
	aspect-ratio: 16 / 9;
	margin-bottom: 24px;
	margin-top: 8px;
	position: relative;
	width: 100%;
	z-index: 1;
	@media ${devices.tablet} {
		max-height: 432px;
		margin-top: 0;
		margin-bottom: 32px;
		max-width: 767px;
	}
	&:after {
		${stripeBg('100%', '100%', '-6px', '-6px')}
		display: none;
		z-index: -1;
		@media ${devices.laptop} {
			display: block;
		}
	}
`;

const ReactPlayerWistiaWrapper = styled.div`
	height: 100%;
	left: 0;
	object-fit: cover;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100%;
`;

interface InstructorVideoProps {
	videoUrl: string;
}

const HomeHeroVideo = ({ videoUrl }: InstructorVideoProps) => {
	return (
		<VideoContainer>
			{videoUrl && (
				<ReactPlayerWistiaWrapper>
					<ReactPlayerWistia url={videoUrl} />
				</ReactPlayerWistiaWrapper>
			)}
		</VideoContainer>
	);
};

export default HomeHeroVideo;
