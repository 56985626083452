'use client';

import { useState } from 'react';

import NavigationSite from '../../Shares/NavSite/MenuSlide';

import HomeActivities from './HomeActivities';
import HomeCommunities from './HomeCommunities';
import HomeCounter from './HomeCounter';
import HomeFeedback from './HomeFeedback/HomeFeedback';
import HomeHero from './HomeHero';
import HomeLectures from './HomeLectures';
import HomeMeta from './HomeMeta';
import HomeOffer from './HomeOffer';
import HomeStudentInterview from './HomeStudentInterview';
import HomeTrainings from './HomeTrainings';
import HomeTutor from './HomeTutor';
import HomeUpComingPrograms from './HomeUpComingPrograms';
import HomeVideos from './HomeVideos';
import PromotionModal from './PromotionModal';
import CooperativeCompany from '@/components/Shares/CooperativeCompany';
import GetInTouch from '@/components/Shares/GetInTouch';
import VideoPlayerModal from '@/components/Shares/Modal/VideoPlayerModal';
import useSocketMeetupStatus from '@/hooks/zoom/useSocketMeetupStatus';

interface HomeProps {
	trainings: TrainingCard[];
	programs: [IProgramCard[], IProgramCard[], IProgramCard[]];
	skillVideos: TVideoCard[];
	interviewVideos: TVideoCard[];
	teachers: ITeacherCard[];
	offers: ITestimonialOffer[];
	feedbacks: IFeedback[];
	employedAt: string[];
	videos: HomeVideo[];
	promotionImage: string;
	promotionImageMobile: string;
	promotionUrl: string;
	communities: IFetchCareerCommunity[];
	meetups: IMeetup[];
	homeHeroVideoUrl: string;
}

const Home = ({
	trainings,
	programs,
	skillVideos,
	interviewVideos,
	teachers,
	offers,
	employedAt,
	videos = [],
	promotionImage,
	promotionImageMobile,
	promotionUrl,
	feedbacks,
	communities,
	meetups,
	homeHeroVideoUrl
}: HomeProps) => {
	// Socket for meetup status
	useSocketMeetupStatus();
	// Video for video player modal, also as a control for modal open/close
	const [video, setVideo] = useState<TVideoCard | HomeVideo>();

	const HomePageSections = [
		{ id: 'homeMeetupsRef', text: '匠人学院活动' },
		{ id: 'homeTrainingsRef', text: '训练营' },
		{ id: 'homeUpComingProgramsRef', text: '近期开课' },
		{ id: 'homeLectureRef', text: '匠人TV' },
		{ id: 'homeTutorRef', text: '匠人导师团队' },
		{ id: 'homeStudentInterviewRef', text: '学员故事' },
		{ id: 'homeOfferRef', text: '学员Offer榜' },
		{ id: 'homeFeedbackRef', text: '学员真实评价' }
	];

	return (
		<div className="bg-[#fff1e7]">
			<HomeHero homeHeroVideoUrl={homeHeroVideoUrl} />
			<HomeCounter employedAt={employedAt} />

			<HomeMeta />
			<div id="homeTrainingsRef">
				<HomeTrainings trainings={trainings || []} />
			</div>
			<div id="homeUpComingProgramsRef">
				<HomeUpComingPrograms programs={programs || []} />
			</div>
			<div id="homeMeetupsRef">
				<HomeActivities meetups={meetups} />
			</div>
			<HomeVideos videos={videos} setVideo={setVideo} />

			<div id="homeLectureRef">
				<HomeLectures videos={skillVideos || []} setVideo={setVideo} />
			</div>
			<div id="homeTutorRef">
				<HomeTutor teachers={teachers || []} />
			</div>
			<div id="homeStudentInterviewRef">
				<HomeStudentInterview videos={interviewVideos || []} setVideo={setVideo} />
			</div>
			<div id="homeOfferRef">
				<HomeOffer offers={offers || []} />
			</div>
			<div id="homeFeedbackRef">
				<HomeFeedback feedbacks={feedbacks || []} />
			</div>
			<HomeCommunities communities={communities || []} />
			<CooperativeCompany />
			<GetInTouch />
			<VideoPlayerModal video={video} onClose={() => setVideo(undefined)} />
			<NavigationSite sections={HomePageSections} />
			{promotionImage && (
				<PromotionModal
					promotionImage={promotionImage}
					promotionImageMobile={promotionImageMobile}
					promotionUrl={promotionUrl}
				/>
			)}
		</div>
	);
};

export default Home;
