'use client';

import React from 'react';
import styled from 'styled-components';

import { color } from '@/styles/variables';

const TopHalf = styled.div`
	align-items: center;
	display: flex;
	height: 48px;
	justify-content: center;
	padding: 12px 21px 0;
	width: 144px;
`;

const ArrowContainer = styled.div<{ isContentOpen: boolean }>`
	background-color: ${color.primaryYellow};
	border: solid 1px #000;
	border-radius: 50%;
	cursor: pointer;
	height: 24px;
	margin: 0 39px 11px;
	padding: ${props => (props.isContentOpen ? '11px 11px 11px 10px' : '11px')};
	width: 24px;
`;

const Arrow = styled.div<{ isContentOpen: boolean }>`
	border-bottom: 1px solid #000;
	border-left: 1px solid #000;
	height: 5px;
	transform: translate(-50%, -50%) rotate(${props => (props.isContentOpen ? '225deg' : '45deg')});
	width: 5px;
`;

interface Props {
	isContentOpen: boolean;
	toggleContentOpen: () => void;
}

const ExpandSite = ({ isContentOpen, toggleContentOpen }: Props) => {
	return (
		<TopHalf>
			<ArrowContainer isContentOpen={isContentOpen} onClick={toggleContentOpen}>
				<Arrow isContentOpen={isContentOpen} />
			</ArrowContainer>
		</TopHalf>
	);
};

export default ExpandSite;
