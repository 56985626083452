'use client';

import { Grid } from '@mui/material';
import styled from 'styled-components';

import HomeSection from './components/HomeSection';
import VideoCard from '@/components/Shares/VideoCard';
import { devices } from '@/styles/variables';

const Container = styled.div`
	padding: 24px 0;
`;
const CardGridContainer = styled.div`
	display: none;
	@media ${devices.tablet} {
		display: flex;
	}
`;
const CardGridContainerMobile = styled.div`
	display: flex;
	@media ${devices.tablet} {
		display: none;
	}
`;

interface HomeStudentInterviewProps {
	videos: TVideoCard[];
	setVideo: (video: TVideoCard) => void;
}

const HomeStudentInterview = ({ videos, setVideo }: HomeStudentInterviewProps) => {
	const cornerImage = {
		src: '/image/home/student-interview.svg',
		alt: 'student-interview',
		width: 98,
		height: 74
	};

	return (
		<HomeSection
			title="学员故事 - IT培训经验分享"
			description="展示了匠人学员的成功经历。他们分享了通过匠人训练营后如何取得实际工作技能及项目实践，成功实现澳洲IT职业发展。这些故事鼓励和启发其他潜在学员，为求职提供了宝贵的培训学习及面试经验分享。加入我们，启程澳洲IT职业之路。"
			btnTitle="更多学员故事"
			btnLink="/videos?tab=student&page=1"
			theme="light"
			cornerImage={cornerImage}
		>
			{videos.length === 0 && '暂无数据'}
			{videos.length > 0 && (
				<Container>
					<CardGridContainer>
						<Grid
							container
							columnSpacing={{
								miniMobile: 0,
								tablet: 4
							}}
							rowSpacing={4}
						>
							{videos.map(video => {
								return (
									<Grid
										item
										key={video.id}
										miniMobile={6}
										tablet={6}
										largeLaptop={3}
									>
										<VideoCard
											id={video.id}
											slug={video.slug}
											thumbnail={video.thumbnail}
											title={video.title}
											duration={video.duration}
											youtubePublishTime={video.youtubePublishTime}
											handleClick={() => setVideo(video)}
										/>
									</Grid>
								);
							})}
						</Grid>
					</CardGridContainer>
					<CardGridContainerMobile>
						<Grid
							container
							columnSpacing={{
								miniMobile: 2,
								tablet: 2
							}}
							rowSpacing={2}
						>
							{videos.map(video => {
								return (
									<Grid
										item
										key={video.id}
										miniMobile={6}
										tablet={6}
										largeLaptop={3}
									>
										<VideoCard
											id={video.id}
											slug={video.slug}
											thumbnail={video.thumbnail}
											title={video.title}
											duration={video.duration}
											youtubePublishTime={video.youtubePublishTime}
											handleClick={() => setVideo(video)}
										/>
									</Grid>
								);
							})}
						</Grid>
					</CardGridContainerMobile>
				</Container>
			)}
		</HomeSection>
	);
};

export default HomeStudentInterview;
